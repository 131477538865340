
import { Component, Mixins, Prop } from "vue-property-decorator";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";
import { GunBoxEntity } from "@/entities/gun-box.entity";
import Price from "@/components/base/Price.vue";
import BattleBox from "@/components/battles/BattleBox.vue";

@Component({
  components: {
    Price,
    BattleBox,
  },
})
export default class BattleCarousel extends Mixins(FindAllGunBoxMixin) {
  @Prop() gunBoxIds!: string[];

  get gunBoxes(): GunBoxEntity[] {
    return this.gunBoxIds.map((_id) => this.findAllGunBoxMap[_id]);
  }
}
