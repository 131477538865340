
import { Vue, Component, Mixins, Prop } from "vue-property-decorator";
import { IBattle } from "@/interfaces/battle.interface";
import FindAllGunBoxMixin from "@/mixins/find-all-gun-box.mixin";
import Price from "@/components/base/Price.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import BattleUser from "@/components/battles/BattleUser.vue";
import { COLORS } from "@/components/battles/constants";
import BattleCarousel from "@/components/battles/BattleCarousel.vue";

@Component({
  components: {
    Price,
    GradientBtn,
    BattleUser,
    BattleCarousel,
  },
})
export default class BattleCard extends Vue {
  @Prop() value!: IBattle;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  private colors = Object.freeze(COLORS);
}
