
import { Vue, Component, Prop, InjectReactive } from "vue-property-decorator";
import { BattleUserEntityFragment } from "@/graphql/fragments";
import gql from "graphql-tag";
import { IBattleUser } from "@/interfaces/battle.interface";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";

@Component({
  apollo: {
    user: {
      query: gql`
        query ($_id: ID!) {
          findOneBattleUser(_id: $_id) {
            ...BattleUserEntity
          }
        }
        ${BattleUserEntityFragment}
      `,
      variables() {
        return { _id: this.userId };
      },
      skip() {
        return !!this.user || !this.userId;
      },
      update(data) {
        return data.findOneBattleUser;
      },
    },
  },
})
export default class BattleUser extends Vue {
  @InjectReactive(USER_SYMBOL) selfUser!: AuthUserEntity | null;
  @Prop() userId!: string | null;
  @Prop() battleId!: string | null;
  @Prop() color!: string;
  @Prop() joinIndex!: number | null;
  @Prop({ type: Boolean, default: false }) winner!: boolean;

  private user: IBattleUser | null =
    this.selfUser && this.selfUser._id === this.userId
      ? this.selfUser
      : this.userId
      ? this.$apollo.provider.defaultClient.readFragment({
          id: "BattleUserEntity:" + this.userId,
          fragment: BattleUserEntityFragment,
        })
      : null;
}
