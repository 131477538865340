
import { Vue, Component, Prop } from "vue-property-decorator";
import { GunBoxEntity } from "../../entities/gun-box.entity";
import Price from "@/components/base/Price.vue";

@Component({
  components: {
    Price,
  },
})
export default class BattleBox extends Vue {
  @Prop() value!: GunBoxEntity;
  @Prop({ type: Boolean, default: false }) hideName!: boolean;
  @Prop({ type: Boolean, default: false }) gray!: boolean;
}
